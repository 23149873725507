@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap");

@import "@cartbot/vehicle-search/dist/styles/vehicle-search.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --border: 216 34% 17%;
    --input: 216 34% 17%;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border;
    min-width: 0;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings:
      "rlig" 1,
      "calt" 1;
  }
}

@layer components {
  .btn {
    @apply inline-flex flex-shrink-0 items-center justify-center space-x-3 whitespace-nowrap rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white no-underline shadow-sm transition hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:pointer-events-none disabled:opacity-25;
  }

  .btn-emerald {
    @apply !bg-emerald-600 !text-emerald-50 hover:!bg-emerald-700 hover:!text-white;
  }

  .btn-group {
    @apply flex gap-x-2;
  }

  .btn-group > .btn {
    @apply !flex-shrink;
  }

  .btn-lg {
    @apply px-5 py-3 text-base;
  }

  .btn-xl {
    @apply rounded-lg px-5 py-4 text-lg;
  }

  .btn-white {
    @apply bg-white/95 text-indigo-600 shadow-sm hover:bg-white;
  }

  .bg-gradient {
    @apply bg-gradient-to-r from-purple-600 to-indigo-600;
  }

  .btn.bg-gradient {
    @apply relative translate-y-0 transition duration-150 hover:-translate-y-0.5 hover:opacity-95 hover:transition;
  }

  .btn-toggle-on {
    @apply relative whitespace-nowrap rounded-md border-indigo-700 bg-white px-6 py-2 text-sm font-medium text-indigo-700 shadow-sm hover:bg-indigo-50 focus:z-10 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-700;
  }

  .btn-toggle-off {
    @apply relative whitespace-nowrap rounded-md border border-transparent px-6 py-2 text-sm font-medium text-indigo-200 hover:bg-indigo-800 focus:z-10 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-700;
  }

  .btn-sm {
    @apply inline-flex items-center space-x-2 rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:bg-indigo-400 disabled:hover:bg-indigo-400;
  }

  .btn-light {
    @apply bg-indigo-100 text-indigo-700 hover:bg-indigo-200;
  }

  .btn-light-emerald {
    @apply bg-emerald-100 text-emerald-700 outline-none hover:bg-emerald-200 focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2;
  }

  .btn-outline {
    @apply border border-gray-300 bg-gray-50 text-gray-700 hover:bg-gray-100 focus:ring-2 focus:ring-gray-500 focus:ring-offset-2;
  }

  .btn-danger {
    @apply bg-danger-500 hover:bg-danger-600 focus:ring-danger-500 !important;
  }

  .btn-primary {
    @apply bg-primary hover:bg-primary focus:ring-primary !important;
  }

  .btn-success {
    @apply bg-success-500 hover:bg-success-600 focus:ring-success-500 !important;
  }

  .btn-info {
    @apply bg-info-500 hover:bg-info-600 focus:ring-info-500 !important;
  }

  .btn-warning {
    @apply bg-warning-500 hover:bg-warning-600 focus:ring-warning-500 !important;
  }

  .btn-neutral {
    @apply bg-gray-400 hover:bg-gray-500 !important;
  }

  .btn-shopify {
    @apply bg-emerald-600 text-emerald-50 hover:bg-emerald-700 hover:text-white !important;
  }

  .btn.animate {
    @apply relative translate-y-0 transform-cpu transition duration-150 hover:-translate-y-0.5 hover:opacity-95 hover:transition;
  }

  .btn-icon {
    @apply !m-0 aspect-square size-8 items-center justify-center !p-0;
  }

  .link {
    @apply text-indigo-600 hover:text-indigo-900;
  }
}

@layer base {
  /* forms */

  table {
    @apply w-full;
  }

  th {
    @apply bg-white px-3 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500;
  }

  td {
    @apply whitespace-normal break-words px-3 py-3 text-xs text-gray-500;
  }

  tr > td {
    @apply bg-white;
  }

  tr:nth-child(even) > td {
    @apply bg-gray-50;
  }

  tr.unpublished {
    @apply bg-unpublished-100 !important;
  }

  /* inputs */

  input[type="text"]:not(.input-with-addon),
  input[type="email"]:not(.input-with-addon),
  input[type="tel"]:not(.input-with-addon),
  input[type="url"]:not(.input-with-addon),
  input[type="number"]:not(.input-with-addon),
  [type="password"]:not(.input-with-addon),
  [type="url"]:not(.input-with-addon),
  textarea,
  select,
  select[multiple],
  trix-editor {
    @apply block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm;
  }

  input[type="text"],
  input[type="email"],
  input[type="url"],
  input[type="number"],
  [type="password"],
  [type="url"],
  textarea {
    @apply invalid:border-danger-500 invalid:ring-danger-500 focus:invalid:border-danger-500 focus:invalid:ring-danger-500 read-only:pointer-events-none read-only:text-gray-400 read-only:focus:border-gray-300 read-only:focus:ring-0;
  }

  trix-editor ul {
    list-style-type: disc;
    padding-left: 1.5rem;
  }

  trix-editor ol {
    list-style-type: decimal;
    padding-left: 1.5rem;
  }

  /* forms */
  label {
    @apply mb-1 inline-flex text-xs font-medium text-gray-700;
  }
}

@layer utilities {
  .form-group {
    @apply mb-5;
  }

  .card {
    @apply inline-block transform overflow-hidden rounded-lg bg-white px-4 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-12 sm:align-middle;
  }

  .heading {
    @apply font-extrabold tracking-tight text-black;
  }

  a {
    @apply text-indigo-500 no-underline transition;
  }

  .tab-label {
    @apply flex whitespace-nowrap border-b-2 border-transparent px-1 py-4 text-xs font-medium text-gray-500 hover:border-gray-200 hover:text-gray-700 focus-visible:outline-0;
  }

  .tab-label.active {
    @apply border-indigo-500 text-indigo-600;
  }

  /* Marketplace */

  .mp-nav-link {
    @apply flex items-center justify-center rounded-lg p-2 px-3 text-xs font-bold uppercase tracking-widest text-indigo-500 no-underline hover:bg-indigo-100 hover:text-indigo-700;
  }

  .heading-sm {
    @apply text-xs font-bold uppercase tracking-widest text-indigo-600;
  }

  .product-card {
    @apply flex min-h-[400px] w-full flex-col space-y-3 rounded-lg border-4 border-white bg-white p-3 shadow transition hover:shadow-xl hover:shadow-indigo-900/20;
  }

  .product-view {
    @apply fixed bottom-0 left-0 right-0 top-0 z-50 h-screen w-screen px-[32vh] py-[16vh] backdrop-blur-sm backdrop-brightness-90;
  }

  /* TipTap Rich Text Editor */
  .partbot-text-editor {
    @apply h-full w-full focus:outline-none;
  }

  .partbot-text-editor table {
    @apply w-full !important;
  }

  .editor-menu-btn {
    @apply inline-block border-b border-t bg-stone-100 px-2 py-1 text-sm font-medium uppercase leading-tight text-stone-500 transition duration-150 ease-in-out hover:bg-stone-200 focus:bg-stone-200 focus:outline-none focus:ring-0 active:bg-stone-200;
  }

  .editor-menu-btn.left {
    @apply rounded-l border-l;
  }

  .editor-menu-btn.right {
    @apply rounded-r border-r;
  }

  .editor-menu-btn.active {
    @apply bg-stone-500 text-stone-100;
  }

  .aa-Item[aria-selected="true"] {
    @apply rounded bg-indigo-100;
  }

  /* clears the ‘X’ from Internet Explorer */
  input[type="search"]::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }
  input[type="search"]::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }
  /* clears the ‘X’ from Chrome */
  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    display: none;
  }

  ::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    background: #e0e7ff;
    border-radius: 1ex;
    -webkit-border-radius: 1ex;
  }

  ::-webkit-scrollbar-thumb {
    background: #5048e5;
    border-radius: 1ex;
    -webkit-border-radius: 1ex;
  }

  ::-webkit-scrollbar-corner {
    background: #e0e7ff;
  }

  [data-sticky-td] {
    position: sticky;
  }

  [data-sticky-last-left-td] {
    @apply border-r;
  }

  [data-sticky-first-right-td] {
    @apply border-l;
  }
}

/* Algolia Components */

.ais-Pagination-list {
  @apply flex items-center gap-3;
}

.ais-Pagination-link {
  @apply flex h-8 w-8 items-center justify-center rounded bg-indigo-100 text-center text-sm text-indigo-600 no-underline hover:bg-indigo-500 hover:text-indigo-100;
}

.ais-Pagination-item--selected .ais-Pagination-link {
  @apply bg-indigo-600 text-indigo-100;
}

.ais-Pagination-item--disabled {
  @apply pointer-events-none opacity-40;
}
